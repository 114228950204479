<template>
  <div class="section-container signin">
    <v-container>
      <v-row class="signin" justify="center">
        <v-col cols="12" md="6" class="right text-center">
          <img
            src="@/assets/images/Full-Logo-Gold.png"
            class="site-logo mb-5"
          />
          <div class="inner">
            <h2 class="text-center mb-5">Enter Your Email</h2>
            <validation-observer ref="forgotObserver" v-slot="{ handleSubmit }">
              <v-form
                ref="forgotForm"
                @submit.prevent="handleSubmit(sendEmail)"
              >
                <v-row>
                  <v-col cols="12" sm="12" v-if="message !== null">
                    <v-alert :type="type">{{ message }}</v-alert>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|email"
                      name="Email"
                      mode="lazy"
                    >
                      <v-text-field
                        v-model="email"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Email"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        :hide-details="!errors.length"
                      ></v-text-field>
                    </validation-provider>
                    <div
                      class="forgot-password my-3 text-right font-weight-bold"
                    >
                      <router-link to="/Login">Login</router-link>
                    </div>
                  </v-col>
                </v-row>
                <div class="text-center">
                  <v-btn
                    class="signin-btn"
                    type="submit"
                    primary
                    dark
                    rounded
                    :disabled="loading"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Footer from "@/components/Footer.vue";
import UserService from "@/services/UserService";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Footer,
  },
  data() {
    return {
      email: null,
      loading: false,
      message: null,
      type: null,
    };
  },
  watch: {
    email() {
      this.message = null;
      this.type = null;
    },
  },
  methods: {
    sendEmail() {
      this.loading = true;
      UserService.forgotPassword({ email: this.email })
        .then(() => {
          this.loading = false;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Reset Password link has been sent to your Registered email.",
              type: "success",
            },
            { root: true }
          );
          this.email = null;
          this.$refs.forgotObserver.reset();
          this.$refs.forgotForm.reset();
          setTimeout(() => {
            this.message =
              "Reset Password link has been sent to your Registered email.";
            this.type = "success";
          }, 100);
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.errorMessage;
          this.type = "error";
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: error.response.data.errorMessage,
              type: "error",
              status: error.response.status,
            },
            { root: true }
          );
        });
    },
  },
};
</script>
